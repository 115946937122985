.h {
    height: 100%;
    position: relative;
    left: 0;
    z-index: 5000;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(80px);
  }
  .subpoduct-head-items{
    max-width: 200px;
    width: 100%;
   
  }
  .sub-sub-items{
    flex-wrap: wrap;
    max-width: 170px;
    width: 100%;
    margin-bottom: 15px;
  }
  .subproduct-row{
    display: flex;
    width: 100%;
  }
  .products-lists{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
  .inner-wrapper {
    padding: 80px 0;
    height: 450px;
    overflow-y: scroll;
  }
  .inner-wrapper::-webkit-scrollbar{
    display: none;
  }
  
  .me-50 {
    margin-right: 50px;
  }
  
  .qlux-product {
    font-family: Orbitro-Semibold;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    color: #fff;
    text-decoration: none;
    display: block;

    &:hover{
      color:#d5b36b;
    }
  }
  .sub-sub-items ul li a{
    &:hover{
    color: #d5b36b !important;}
  }
  