footer {
  padding: 60px 0 !important;
  background: #000;
}

.first-row {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  width: 100%;
}

.social-wrapper {
  display: flex;
  width: calc(100% - 540px);
  justify-content: space-between;
}

.second-row {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  width: 100%;

  .reach-us {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      width: 1px;
      border-right: 1px solid rgba(255, 255, 255, 0.08);
      height: calc(100% + 68px);
      top: -51px;
      right: 16px;
    }
  }
}

.gehost {
  max-width: 410px;
  width: 100%;
  position: relative;
  // margin-right: 66px;

  .hostimg {
    margin-bottom: 25px;
    display: block;

  }

  &::after {
    content: "";
    position: absolute;
    width: 1px;
    border-right: 1px solid rgba(255, 255, 255, 0.08);
    height: calc(100% + 115px);
    top: -63px;
    right: -50px;
  }
}

.ghost-desc {
  font-family: Poppins-Normal, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.75);
}

.footer-links {
  margin-left: 80px;
}

.fl-heading {
  font-family: Orbitro-Semibold, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #fff;

  margin-bottom: 25px;
}

.footer-link {
  margin-bottom: 15px !important;

  &:last-child {
    margin-bottom: 0 !important;
  }

  a {
    font-family: Poppins-Normal, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-decoration: none;
    display: block;
    color: rgba(255, 255, 255, 0.75);

    &:hover,
    &:focus {
      color: #d5b36b;
    }
  }
}

.location-div {
  max-width: 410px;
  width: 100%;
  margin-right: 66px;
}

.loc-address {
  font-family: Poppins-Normal, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  margin-left: 15px;
  color: rgba(255, 255, 255, 0.75);
}

.contactdial {
  font-family: Poppins-Normal;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.75);
  text-decoration: none;
  margin-bottom: 17px;

  &:hover,
  &:focus {
    color: #d5b36b;
    text-decoration: underline;
  }
}

.msg {
  font-family: Poppins-Normal;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.75);
  text-decoration: none;

  &:hover,
  &:focus {
    color: #d5b36b;
    text-decoration: underline;
  }
}

.social-icon {
  a {
    height: 28px;
    width: 28px;
    color: #fff;
    text-decoration: none;
    margin-right: 15px;

    i {
      font-size: 22px;
    }

    &:hover,
    &:focus {
      color: #d5b36b;
    }
  }
}

.copyright {
  .cpy {
    font-family: Poppins-Normal;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: rgba(255, 255, 255, 0.45);
  }

  .pages {
    a {
      font-family: Poppins-Normal;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: rgba(255, 255, 255, 0.45);
      opacity: 0.85;
      text-decoration: none;
    }
  }
}

@media screen and(max-width:992px) {
  .first-row {
    display: block !important;
    width: 90% !important;
    margin: 0 auto !important;
  }

  .gehost {
    max-width: 100%;
    width: 410px;
  }
  .company,.product, .sitemap {
    display: none !important;
  }

  .gehost::after {
    display: none !important;
  }

  .second-row .reach-us::after {
    display: none;
  }

  .second-row {
    flex-direction: column !important;
    width: 90%;
    margin: 20px auto 0 auto !important;
  }

  .social-wrapper {
    width: 100% !important;
    margin-top: 30px !important;
  }

  .copyright {
    flex-direction: column;
  }

  .pages {
    margin-top: 10px;
  }
}

@media screen and(max-width:768px) {}

@media screen and(max-width:576px) {
  .gehost {
    width: 100% !important;
    max-width: 100% !important;
  }
  .company,.product, .sitemap {
    display: none !important;
  }

  .second-row .reach-us{
    margin-top: 10px !important;
  }
  .first-row {
    width: 100% !important;
    margin: 0 !important;
  }

  .second-row {
    width: 100% !important;
    margin: 0 !important;
  }


  .social-wrapper {
    flex-direction: column;
    justify-content: center !important;
    margin-top: 10px !important;
  }

  .followus {
    margin: 20px 0;
  }

}