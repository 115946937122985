header {
  background: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(30px);
  position: fixed;
  width: 100%;
  z-index: 500;
}
.navbar {
  padding: 1rem 0 !important;
  display: flex;
  align-items: center;
  height: 80px !important;
}
.nav-menu {
 display: flex;
 list-style: none;
 margin: 0 !important;
 padding: 0 !important;
}
.fa-bars {
  color:#d5b36b;
}
.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}
.inquery {
  text-align: center;
}

.active-clr {
  color: #d5b36b !important;
}
.nav-item {
  margin: 0 17px;

  &:first-child {
    margin-left: 0 !important;
  }

  &:last-child {
    margin-right: 0 !important;
  }

  :hover,
  :focus {
    color: #d5b36b !important;
  }
}

.nav-link {
  font-family: Poppins-Normal, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  padding: 0 !important;
}
.inquery-res{
  display: none;
  margin-top: 20px !important;
}
.inq-res{
  padding: 15px 70px !important ;
}
.inquery,.inquery-res {
  .inq,.inq-res {
    text-decoration: none;
    font-family: Orbitro-Semibold, sans-serif;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 2px;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    padding: 10px 30px;
    display: inline-block;
    border: 1px solid #d5b36b;
    box-sizing: border-box;
    box-shadow: 0px 21px 38px -12px rgba(186, 60, 6, 0.35);
    border-radius: 75px;

    &:hover {
      background: #d5b36b;
      color: #121212;
      border: 1px solid #121212;
      border-radius: 75px;
    }
  }
}


// responsive Menu

.multi-level, .item ul, .nav1 input[type="checkbox"] {
  display: none;
}
#menu:checked ~ .multi-level, .item input:checked ~ ul {
  display: block;
}
/*Arrow*/

.arrow1 {
  width: 12px;
  height: 12px;
  vertical-align: middle;
  float: left;
  z-index: 0;
  margin: 17px 1em 0 2em;
}
.item input + .arrow1 {
  transform: rotate(-90deg);
  transition: 0.1s;
}
.item input:checked + .arrow1 {
  transform: rotate(0deg);
  transition: 0.1s;
}
.multi-level{
  padding: 12px;
}


/*Styles*/

label:hover {
  cursor: pointer;
}
label {
  width: 100%;
  display: block;
  z-index: 3;
  position: relative;
}
.nav1 {
  width: 100%;
  transition: all 0.5s ease;
  overflow-y: scroll;
  overflow-x: hidden;
}

#nav-icon {
  font-family: Poppins-Normal, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  padding: 12px !important;
}

.nav1 ul, .nav1 li, label {
  line-height: 50px;
  margin: 0;
  padding: 0 2em;
  list-style: none;
  text-decoration: none;
  color: #90A4AE;
  font-weight: 100;
  width: 100%;
}
.item ul {
  padding: 0 0.25em;
}













@media screen and (max-width: 1200px) {
 .inquery{
   display: none !important;
 }
 .inquery-res{
   display: block !important;
 }
 .nav-link{
   font-size: 18px !important;
   line-height: 24px !important;
 }
  .nav-item{
    margin: 0 !important;
    // position: relative;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    padding: 10px !important;
  }
  .nav-link{
    padding: 12px  !important;
  }
  .nav-menu.active {
    // background: #242222;
    left: 0;
    background:rgba(0, 0, 0, .9);
    backdrop-filter: blur(30px);
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    position: fixed;
    overflow-y: scroll;
    padding-bottom: 80px !important;
    
  }
  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #d5b36b;
    font-size: 2rem;
  }
}