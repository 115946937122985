:root {
  --home-s: "";
}

.lines {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  max-width: 1245px;
  width: 100%;
  margin: 82px auto 550px auto;
  z-index: -1;
  overflow-x: hidden;
}

.wrapper {
  display: flex;
  height: 100%;
  width: 100%;
}

.line {
  position: relative;
  width: 100%;
  min-width: 145px;
  left: -108px;
  border-right: 1px solid rgba(255, 255, 255, 0.05);
}

.line::after {
  content: "";
  display: block;
  position: absolute;
  height: 7vh;
  width: 2px;
  background: rgba(255, 255, 255, 0.8);
  transform: translateX(-50%);
  box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.8);
}

.line:nth-child(1)::after {
  top: 0;
}

.line:nth-child(2)::after {
  // top: 2%;
  animation: even-lines var(--home-s) linear both alternate infinite;
}

.line:nth-child(3)::after {
  // top: 5%;
  animation: odd-lines var(--home-s) linear both alternate infinite;
  // animation-delay: 1s;
}

.line:nth-child(4)::after {
  // top: 10%;
  animation: even-lines var(--home-s) linear both alternate infinite;
  animation-delay: 1s;
}

.line:nth-child(5)::after {
  // top: 50%;
  animation: odd-lines var(--home-s) linear both alternate infinite;
  animation-delay: 1s;
}

.line:nth-child(6)::after {
  // top: 90%;
  animation: even-lines var(--home-s) linear both alternate infinite;
}

.line:nth-child(7)::after {
  // top: 45%;
  animation: odd-lines var(--home-s) linear both alternate infinite;
  // animation-delay: 1s;
}

.line:nth-child(8)::after {
  // top: 5%;
  animation: even-lines var(--home-s) linear both alternate infinite;
  animation-delay: 1s;
}

.line:nth-child(9)::after {
  // top: 10%;
  animation: odd-lines var(--home-s) linear both alternate infinite;
}
.line:nth-child(10)::after {
  // top: 10%;
  animation: even-lines var(--home-s) linear both alternate infinite;
}



@keyframes even-lines {
  0% {
    top: 0;
  }
  50% {
    top: calc(100% - 7vh);
  }
  100% {
    top: 0;
  }
}
@keyframes odd-lines {
  0% {
    bottom: 0;
  }
  50% {
    bottom: calc(100% - 7vh);
  }
  100% {
    bottom: 0;
  }
}