.home-main {
  position: relative;
  overflow: hidden;

  .wp-link {
    position: fixed;
    bottom: 50px;
    right: 50px;
    z-index: 5000;
  }
  .yt-vdo{
    iframe{
      margin: 0 auto;
      display: block;
      width: 100% !important;
      max-width: 80% !important;
      height: 550px !important;
    }
  }

  .sec-line {
    border: 1px solid rgba(255, 255, 255, 0.1);
    margin-top: 50px !important;
  }

  .hero-start {
    margin-left: 45px;
  }

  .hero-content {
    position: relative;
    //   padding: 160px 0 !important;
    padding-top: 190px;
  }

  .hero-left {
    max-width: 645px;
    width: 100%;
  }

  .head-text {
    margin-bottom: 25px;

    .clr-diff {
      color: #d5b36b;
      text-shadow: 0px 0px 8px rgba(213, 179, 107, 0.8);
    }
  }

  .hero-desc {
    padding-right: 115px;
    margin-bottom: 40px;
  }

  .exploremore {
    font-family: Orbitro-SemiBold,sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    padding: 16px 34px;
    color: #121212;
    background: #d5b36b;
    box-shadow: 0px 21px 38px -12px rgba(213, 179, 107, 0.35);
    border-radius: 75px;
    text-decoration: none;
    display: inline-block;
    border: 1px solid transparent;

    &:hover {
      background: #121212;
      color: #ffffff;
      border: 1px solid #d5b36b;
    }
  }

  .hero-right {
    position: absolute;
    right: 0;
    top: 90px;
    margin-right: -25px;
  }

  .site-stats {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 115px;
  }

  .industries-content {
    margin: 0 45px;
  }

  .industries-yt {
    padding-top: 100px;
  }

  .yt-vdo {
    margin: 50px 0 !important;

    .ytvdo {
      width: 100%;
      height: auto;
    }
  }

  .yt-desc-div {
    max-width: 610px;
    width: 100%;
    margin-left: auto;
    display: flex;
  }

  .list-icon-div {
    margin-right: 15px;
  }

  .about-industries {
    padding-top: 120px;
  }

  .about-content {
    margin: 0 45px;
  }

  .about-heading-div {
    .highlight {
      color: #d5b36b;
      text-shadow: 0px 0px 7px rgba(213, 179, 107, 0.7);
    }
  }

  .about-details {
    display: flex;
    margin: 60px 0;

    .about-details-left {
      width: 47%;
    }

    .about-details-right {
      width: 47%;
    }
  }

  .events-wrapper {
    margin: 0 45px;
  }

  .events-content {
    display: flex;
    flex-wrap: wrap;
    padding-top: 70px;

    @media screen and (max-width:1100px) {
      flex-direction: column !important;
      
    }
    .first{
      @media screen and (max-width:1100px) {
        max-width: 520px;
        width: 100%;
        margin-left: auto;
      }
      @media screen and (max-width:576px) {
        max-width: 95%;
        width: 100%;
        margin: 0 auto !important;
      }
    }
  }
  .mt-100 {
    margin-top: -140px;
  }

  .plant-wrapper {
    flex: 0.8;
  }
  .plant-wrapper-unset {
    flex: unset;
    width: 100% !important;
    max-width: 100% !important;

    @media screen and (max-width:1200px) {
      flex: 1 !important;
      margin-top: 0 !important;
    }
  }
  .plant-box-left{
    max-width: 520px;
    width: 100%;
    margin-right: auto;

    @media screen and (max-width:576px) {
      max-width: 95%;
      width: 100%;
      margin: 0 auto !important;
    }
  }
  .plant-box-right{
    max-width: 520px;
    width: 100%;
    margin-left: auto;
    @media screen and (max-width:576px) {
      max-width: 95%;
      width: 100%;
      margin: 0 auto !important;
    }
  }
  .event-desc-div {
    flex: 1.2;
  }

  .event-desc-head {
    margin-bottom: 25px;
    padding-top: 75px;
  }

  .event-desc {
    padding-right: 65px;
  }

  .event-img-div {
    position: relative;
    width: 520px;
    height: 390px;

    .bottom-blur {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      background: rgba(133, 128, 128, 0.24);
      backdrop-filter: blur(9px);
      bottom: 0;
      padding: 15px 20px;

      a {
        font-family: Orbitro-Medium;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #d5b36b;
        text-decoration: none;
      }
    }
  }

  .event-2 {
    // display: flex;

    .bottom-blur {
      background: rgba(0, 0, 0, 0.24);
      backdrop-filter: blur(9px);
    }
  }

  .event2-text {
    margin-top: 20px;

    .event-desc {
      padding: 0 !important;
    }
  }

  .event2-desc {
    max-width: 500px;
    width: 100%;
    margin-left: 15px;

    .event-desc {
      padding: 0 !important;
      margin-top: 30px;
    }
  }

  .latest-events {
    padding: 70px 0;
  }

  .latest-content {
    margin: 0 45px;

    .lt-first {
      margin-bottom: 50px;
    }
  }

  .mainevent-img {
    position: relative;
    .maineventimg{
      height: 545px;
      max-width: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
    .bottom-blur {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      position: absolute;
      bottom: 0;
      background: rgba(0, 0, 0, 0.24);
      backdrop-filter: blur(20px);
      padding: 15px 20px;

      .event1-left {
        display: flex;
        flex-direction: column;

        span {
          font-family: Orbitro-Medium;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 26px;
          color: #ffffff;
          margin-bottom: 10px;
          display: inline-block;
        }

        .date {
          font-family: Poppins-Normal;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
          color: rgba(255, 255, 255, 0.75);
          text-decoration: none;
        }
      }

      .event1-right {
        a {
          font-family: Orbitro-Medium;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          color: #d5b36b;
          text-decoration: none;
        }
      }
    }
  }
}

// MediaQuery

@media screen and (max-width: 1200px) {
  .home-main .hero-left {
    max-width: 100%;
    width: 100%;

  }
  .home-main{
    .yt-vdo{
      iframe{
        max-width: 90% !important;
        height: 450px !important;
      }
    }
  }

  .hero-right {
    display: none !important;
  }
  .site-stats {
    justify-content: space-evenly !important;
  }

  .events-wrapper,
  .about-content,
  .hero-start,
  .latest-content {
    margin: 0 20px !important;
  }

  .event-desc-div {
    margin-right: 20px !important;
  }

  .event-2 {
    margin-top: 45px !important;
  }
}

@media screen and (max-width: 992px) {
  .industries-content {
    margin: 0 20px !important;
  }
  .home-main{
    .yt-vdo{
      iframe{
        max-width: 90% !important;
        height: 350px !important;
      }
    }
  }
  .ind-head {
    span {
      display: inline !important;
    }
  }

  .hero-content {
    padding-top: 120px !important;
  }

  .industries-yt {
    padding-top: 80px !important;
  }

  .sec-line {
    margin-bottom: 0 !important;
  }

  .yt-desc-div {
    max-width: 100% !important;
    width: 100% !important;
  }

  .about-industries {
    padding-top: 80px !important;
  }

  .about-heading-div {
    h2 {
      span {
        display: inline !important;
      }
    }
  }

  .fs-50 {
    font-size: 45px !important;
  }

  .lh-74 {
    line-height: 1.3 !important;
  }

  .about-details {
    flex-direction: column !important;
    margin: 40px 0 !important;
  }

  .about-details-left,
  .about-details-right {
    width: 100% !important;
  }

  .event-desc-div {
    flex: 1 !important;
    margin-right: 10px !important;
  }

  .event-desc-head {
    padding-top: 30px !important;
  }

  .event-desc {
    padding-right: 20px !important;
  }

  .event-img-div {
    flex: 1.3 !important;
  }

  .plant1,
  .plant2 {
    max-width: 100% !important;
  }

  .bottom-blur {
    padding: 10px !important;
  }

  .events-wrapper,
  .about-content,
  .hero-start,
  .latest-content,
  .industries-content {
    margin: 0 10px !important;
  }

  .event2-text {
    flex: 1 !important;
  }

  .event2-desc {
    max-width: 100% !important;
    margin-left: 0 !important;
  }
}

@media screen and (max-width: 768px) {
  .home-main{
    .yt-vdo{
      iframe{
        max-width: 95% !important;
        height: 350px !important;
      }
    }
  }
  .hero-desc {
    padding-right: 0 !important;
  }

  .fs-55 {
    font-size: 45px !important;
  }

  .lh-84 {
    line-height: 1.3 !important;
  }

  .site-stats {
    margin-top: 80px !important;
  }

  .fs-50 {
    font-size: 40px !important;
  }

  .events-content {
    flex-direction: column !important;
  }

  .event-img-div {
    order: 1 !important;
    margin: 0 auto !important;
  }

  .event-desc-div {
    margin-right: 0 !important;
  }

  .event-2 {
    flex-direction: column !important;
    margin-top: 0 !important;
  }

  .event-img-div {
    margin-top: 35px !important;
  }

  .event2-text {
    margin-left: 0 !important;
  }

  .lt-first {
    flex-direction: column !important;
    margin-bottom: 25px !important;

    .col-6 {
      width: 100% !important;
    }
  }

  .home-main .sec-line {
    margin-top: 10px !important;
  }
}

@media screen and (max-width: 576px) {
  .wp-link {
    bottom: 25px !important;
    right: 25px !important;
  }
  .home-main{
    .yt-vdo{
      iframe{
        max-width: 95% !important;
        height: 350px !important;
      }
    }
  }

  .wp {
    height: 60px !important;
    width: 60px !important;
  }

  .events-wrapper,
  .about-content,
  .hero-start,
  .latest-content,
  .industries-content {
    margin: 0 !important;
  }

  .fs-55 {
    font-size: 38px !important;
  }

  .fs-50 {
    font-size: 32px !important;
  }

  .fs-16 {
    font-size: 15px !important;
  }

  .lh-28 {
    line-height: 1.4 !important;
  }

  .head-text {
    margin-bottom: 15px !important;

    span {
      display: inline !important;
    }
  }

  .hero-desc {
    margin-bottom: 25px !important;
  }

  .home-main .site-stats {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 30px !important;

    .coustomer {
      margin-bottom: 10px !important;
    }

    .manufacturing {
      margin-bottom: 10px !important;
    }
  }

  .home-main .event-desc-head {
    margin-bottom: 10px !important;
  }

  .industries-yt {
    padding-top: 60px !important;
  }

  .home-main .yt-vdo {
    margin: 20px 0 !important;
  }

  .about-industries {
    padding-top: 60px !important;
  }

  .about-heading-div h2 span {
    display: inline !important;
  }

  .home-main .events-content {
    padding-top: 60px !important;

   
  }

  .event-img-div {
    width: 100% !important;
    height: 100% !important;
    margin-top: 15px !important;
  }

  .plant1,
  .plant2 {
    width: 100% !important;
    height: 100% !important;
  }

  .bottom-blur {
    flex-direction: column !important;
    align-items: flex-start !important;

    a {
      margin-top: 10px !important;
    }
  }

  .event1-left {
    span {
      margin-bottom: 0 !important;
    }

    a {
      display: none !important;
    }
  }

  .fs-20 {
    font-size: 18px !important;
  }

  .lh-25 {
    line-height: 1.2 !important;
  }
  .mainevent-img{
    .maineventimg{
      height: 100% !important;
    }
  }
  .home-main .mainevent-img .bottom-blur .event1-left span {
    font-size: 16px !important;
  }

  .home-main .latest-events {
    padding: 60px 0 !important;
  }
}
