body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url(Assests//Images/bg-img.jpg); */

  background-color: #060606 !important;
}
.wp-link {
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 5000;
}
.wp-link .circle-waves {
  border-radius: 50%;
  background-color: #3cc04e;
  width: 60px;
  height: 60px;
  position: absolute;
  opacity: 0;
  bottom: 1px;
  left: 0px;
  z-index: -1;
  animation: waves 3s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
}
@media screen and (max-width: 576px) {
  .wp-link {
    bottom: 25px !important;
    right: 25px !important;
  }
}
.container {
  max-width: 1245px !important;
  margin: 0 auto !important;
}
#root {
  position: relative;
  overflow: hidden;
}
#root::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url(./Assests//Images//bgimg2.webp);
  opacity: 5%;
  z-index: -10;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@font-face {
  font-family: Poppins-Normal;
  src: url(Fonts/poppins/Poppins-Regular.ttf);
}
@font-face {
  font-family: Poppins-Semibold;
  src: url(Fonts/poppins/Poppins-SemiBold.ttf);
}
@font-face {
  font-family: Orbitro-Bold;
  src: url(Fonts/orbitron/Orbitron-Bold.ttf);
}
@font-face {
  font-family: Orbitro-Semibold;
  src: url(Fonts/orbitron/Orbitron-SemiBold.ttf);
}
@font-face {
  font-family: Orbitro-Medium;
  src: url(Fonts/orbitron/Orbitron-Regular.ttf);
}

/* ********************************* Font-families*********************************** */

.pn {
  font-family: Poppins-Normal, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: #d5b36b;
}
.pn-w {
  font-family: Poppins-Normal, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.02em;
}

.o600-w {
  font-family: Orbitro-Semibold, sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #ffffff;
}
.o600-highlight {
  font-family: Orbitro-Semibold, sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #d5b36b;
}
.p600-w {
  font-family: Poppins-Semibold, sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #ffffff;
  font-size: 26px;
  line-height: 40px;
}
.o600-b {
  font-family: Orbitro-Bold, sans-serif;
  color: #121212;
  font-style: normal;
}
.o500-w {
  font-family: Orbitro-Medium, sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
}
/* *************************font-sizes****************** */
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}
.fs-26 {
  font-size: 26px;
}
.fs-32 {
  font-size: 32px;
}
.fs-36 {
  font-size: 36px;
}
.fs-45 {
  font-size: 45px;
}
.fs-50 {
  font-size: 50px;
}
.fs-55 {
  font-size: 55px;
}

/* *********************line-height****************** */
.lh-18 {
  line-height: 18px;
}
.lh-21 {
  line-height: 21px;
}
.lh-24 {
  line-height: 24px;
}
.lh-25 {
  line-height: 25px;
}
.lh-26 {
  line-height: 26px;
}
.lh-27 {
  line-height: 27px;
}
.lh-28 {
  line-height: 28px;
}
.lh-40 {
  line-height: 40px;
}
.lh-45 {
  line-height: 45px;
}
.lh-54 {
  line-height: 54px;
}
.lh-74 {
  line-height: 74px;
}
.lh-84 {
  line-height: 84px;
}
.circle-waves:nth-child(0){
  animation-delay: 0s;
}
.circle-waves:nth-child(1){
  animation-delay: 1s;
}
.circle-waves:nth-child(2){
  animation-delay: 2s;
}
.circle-waves:nth-child(3){
  animation-delay: 3s;
}
@keyframes waves {
  0% {
    transform: scale(0.55, 0.55);
    opacity: 0.6;
  }

  100% {
    transform: scale(1.8, 1.8);
    opacity: 0;
  }
}
